<template>
  <div id="app" class="bg-gray-100">
    <section class="py-6">
      <div
        class="container mx-auto mb-16 flex flex-col lg:min-h-[calc(70vh-64px)] md:min-h-[calc(70vh-64px)] items-center"
      >
        <div class="h-full w-full md:w-2/5 mx-auto my-auto mt-12">
          <img
            src="../assets/coming-soon.svg"
            alt="coming soon"
            class="w-1/2 h-auto mx-auto"
          />
        </div>
        <div class="h-full w-full md:w-1/2 mx-auto mb-auto mt-6 md:-mt-12">
          <p class="lg:text-3xl md:text-2xl sm:text-1xl font-semibold text-gray-600 mx-auto">
            {{ $t("comingSoon") }}
      </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "ComingSoon",
}
</script>

<template>
  <div id="app">
    <header class="bg-white shadow relative">
      <div class="container mx-auto px-6 flex justify-between items-center font-semibold text-sm">
        <div class="flex items-center">
          <router-link to="/" class="flex items-center">
            <img src="../assets/home-logo.svg" alt="Tokenomics Logo" class="h-9 lg:ml-4 w-auto" />
          </router-link>
        </div>

        <nav class="hidden lg:flex lg:items-center ml-auto text-base">
          <div class="relative pt-5 pb-5 group">
            <router-link
              to="/"
              class="text-gray-800 hover:text-primary-700 font-black text-lg mx-4"
              >{{ $t('header.home') }}</router-link
            >
            <div
              class="absolute bottom-0 left-0 w-full h-[2px] bg-primary-700 rounded-full transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"
              :class="{ 'scale-x-100': $route.matched.some(({ name }) => name === 'home') }"
            ></div>
          </div>
          <div class="relative pt-5 pb-5 group">
            <router-link
              to="/about-us"
              class="text-gray-800 hover:text-primary-700 font-black text-lg mx-4"
              >{{ $t('header.aboutUs') }}</router-link
            >
            <div
              class="absolute bottom-0 left-0 w-full h-[2px] bg-primary-700 rounded-full transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"
              :class="{ 'scale-x-100': $route.matched.some(({ name }) => name === 'about-us') }"
            ></div>
          </div>
          <div
            class="relative pt-5 pb-5 group"
            @mouseenter="showSolutionsDropdown"
            @mouseleave="hideSolutionsDropdown"
          >
            <button
              class="text-gray-800 hover:text-primary-700 text-lg mx-4 font-black flex items-center"
              :class="{
                'text-primary-700': $route.matched.some(({ name }) =>
                  ['ico-portal', 'advisory', 'services'].includes(name)
                )
              }"
            >
              {{ $t('header.solutions') }}

              <svg
                class="w-5 h-5 ml-1"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                v-if="!isSolutionsDropdownOpen"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
              <img
                v-if="isSolutionsDropdownOpen"
                src="../assets/arrow-up.svg"
                alt="Certification 1"
                class="object-contain w-4 h-4 ml-2"
              />
            </button>
            <div
              v-if="isSolutionsDropdownOpen"
              class="absolute mt-5 w-96 bg-white rounded-md shadow-lg border border-gray-200"
            >
              <div class="p-4">
                <div class="flex">
                  <div class="w-1/2 pr-4 text-left">
                    <h3 class="font-light text-gray-700 mb-2">Fintech</h3>
                    <router-link to="/coming-soon-ico-portal" class="block">
                      <div class="flex mr-auto items-left mb-2 p-2 hover:bg-gray-100 rounded">
                        <img src="../assets/solutions-1.svg" alt="solutions ICO" />
                        <div class="ml-4">
                          <h4 class="font-semibold">ICO Portal</h4>
                          <p class="text-sm text-gray-600 font-normal">Coming Soon</p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                  <div class="w-1/2 pr-4 text-left">
                    <h3 class="font-light text-left text-gray-700 mb-2">Regtech</h3>
                    <router-link to="/coming-soon-advisory" class="block">
                      <div class="flex mr-auto items-left mb-2 p-2 hover:bg-gray-100 rounded">
                        <img src="../assets/solutions-2.svg" alt="solutions advisory" />
                        <div class="ml-4 mr-auto">
                          <h4 class="font-semibold">Advisory</h4>
                          <p class="text-sm text-gray-600 font-normal">Coming Soon</p>
                        </div>
                      </div>
                    </router-link>
                    <router-link to="/coming-soon-services" class="block">
                      <div
                        class="flex ml-0.5 mr-auto items-left mb-2 p-2 hover:bg-gray-100 rounded"
                      >
                        <img src="../assets/solutions-3.svg" alt="solutions services" />
                        <div class="ml-4">
                          <h4 class="font-semibold">Services</h4>
                          <p class="text-sm text-gray-600 font-normal">Coming Soon</p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute bottom-0 left-0 w-full h-[2px] bg-primary-700 rounded-full transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"
              :class="{
                'scale-x-100': $route.matched.some(({ name }) =>
                  ['ico-portal', 'advisory', 'services'].includes(name)
                )
              }"
            ></div>
          </div>
          <div class="relative pt-5 pb-5 group">
            <router-link
              to="/coming-soon-blogs"
              class="text-gray-800 hover:text-primary-700 font-black text-lg mx-4"
              >{{ $t('header.blogs') }}</router-link
            >
            <div
              class="absolute bottom-0 left-0 w-full h-[2px] bg-primary-700 rounded-full transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"
              :class="{ 'scale-x-100': $route.matched.some(({ name }) => name === 'blogs') }"
            ></div>
          </div>
          <div class="relative pt-5 pb-5 group">
            <router-link
              to="/coming-soon-news"
              class="text-gray-800 hover:text-primary-700 font-black text-lg mx-4"
              >{{ $t('header.news') }}</router-link
            >
            <div
              class="absolute bottom-0 left-0 w-full h-[2px] bg-primary-700 rounded-full transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300"
              :class="{ 'scale-x-100': $route.matched.some(({ name }) => name === 'news') }"
            ></div>
          </div>
        </nav>

        <div class="flex items-center ml-12">
          <router-link
            to="/contact"
            class="bg-primary-700 text-white pl-10 px-6 py-2.5 font-black text-base rounded-xl hover:bg-primary-300 transition duration-300 flex items-center mr-4 hidden lg:flex"
            exactActiveClass="no-active"
          >
            {{ $t('header.contactUs') }}
            <img src="../assets/right-arrow.svg" alt="Tokenomics Logo" class="h-1/2 ml-4" />
          </router-link>

          <p class="text-xl font-light text-gray-300 hidden lg:flex">|</p>

          <div
            class="relative pt-6 pb-4 px-4"
            @mouseenter="showLanguageDropdown"
            @mouseleave="hideLanguageDropdown"
          >
            <button class="text-gray-800 hover:text-gray-600 focus:outline-none">
              <div class="flex items-center">
                <img
                  src="../assets/world-logo.svg"
                  alt="Tokenomics Logo"
                  class="lg:h-4 lg:w-4 md:h-6 md:w-6 object-contain"
                />
              </div>
            </button>
            <div
              v-if="isLanguageDropdownOpen"
              class="text-left absolute mt-4 right-0 w-48 bg-white rounded-md shadow-lg z-10 border border-gray-200"
            >
              <a
                href="#"
                @click="changeLanguage('Thai')"
                :class="[
                  'block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100',
                  { 'text-primary-700': locale === 'TH' }
                ]"
                >ไทย (TH)</a
              >
              <a
                href="#"
                @click="changeLanguage('English')"
                :class="[
                  'block px-4 py-3 text-sm text-gray-700 hover:bg-gray-100',
                  { 'text-primary-700': locale === 'EN' }
                ]"
                >English (EN)</a
              >
            </div>
          </div>

          <button
            @click="toggleMenu"
            class="text-gray-800 hover:text-gray-600 focus:outline-none lg:hidden"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Sliding Menu for Mobile -->
      <transition name="slide">
        <div v-if="isMenuOpen" class="fixed inset-0 bg-white z-50 p-4 lg:hidden">
          <div class="flex justify-end">
            <button
              @click="toggleMenu"
              class="text-gray-800 hover:text-gray-600 focus:outline-none"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
          <nav class="mt-4">
            <router-link
              @click="closeMenu"
              to="/contact"
              class="block mt-4 bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition duration-300 flex items-center justify-center"
              exactActiveClass="no-active"
            >
              <span class="flex-grow text-center">{{ $t('header.contactUs') }}</span>
              <img src="../assets/right-arrow.png" alt="Tokenomics Logo" class="h-4 w-4" />
            </router-link>

            <router-link
              @click="closeMenu"
              to="/"
              class="block mt-4 text-gray-800 hover:text-primary-700 py-3 border-b border-gray-200 text-left font-bold"
            >
              {{ $t('header.home') }}
            </router-link>

            <router-link
              @click="closeMenu"
              to="/about-us"
              class="block mt-4 text-gray-800 hover:text-primary-700 py-3 border-b border-gray-200 text-left font-bold"
            >
              {{ $t('header.aboutUs') }}
            </router-link>

            <div class="mt-4 py-3 border-b border-gray-200">
              <div
                @click="toggleMobileSolutions"
                :class="[
                  'flex justify-between items-center cursor-pointer text-gray-800 hover:text-primary-700',
                  { 'text-primary-700': isMobileSolutionsOpen }
                ]"
              >
                <span
                  class="font-bold"
                  :class="{
                    'text-primary-700': $route.matched.some(({ name }) =>
                      ['ico-portal', 'advisory', 'services'].includes(name)
                    )
                  }"
                  >{{ $t('header.solutions') }}</span
                >
                <svg
                  :class="{ 'transform rotate-180': isMobileSolutionsOpen }"
                  class="w-4 h-4 transition-transform duration-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
              <div v-if="isMobileSolutionsOpen" class="mt-2">
                <div class="ml-4 text-left">
                  <h3 class="font-light text-gray-700 mb-2 text-left">Fintech</h3>
                  <router-link @click="closeMenu" to="/coming-soon-ico-portal" class="block">
                    <div class="flex mr-auto items-left mb-2 p-2 hover:bg-gray-100 rounded">
                      <img src="../assets/solutions-1.svg" alt="Tokenomics Logo" />
                      <div>
                        <div class="ml-4 mr-auto">
                          <h4 class="font-semibold">ICO Portal</h4>
                          <p class="text-sm text-gray-600 font-normal">Coming Soon</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
                <div class="mt-4 ml-4 text-left">
                  <h3 class="font-light text-gray-700 mb-2 text-left align-left">Regtech</h3>
                  <router-link @click="closeMenu" to="/coming-soon-advisory" class="block">
                    <div class="flex mr-auto items-left mb-2 p-2 hover:bg-gray-100 rounded">
                      <img src="../assets/solutions-2.svg" alt="Tokenomics Logo" />
                      <div>
                        <div class="ml-4 mr-auto">
                          <h4 class="font-semibold">Advisory</h4>
                          <p class="text-sm text-gray-600 font-normal">Coming Soon</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                  <router-link @click="closeMenu" to="/coming-soon-services" class="block">
                    <div class="flex mr-auto items-left mb-2 p-2 hover:bg-gray-100 rounded">
                      <img src="../assets/solutions-3.svg" alt="Tokenomics Logo" />
                      <div>
                        <div class="ml-4 mr-auto">
                          <h4 class="font-semibold">Services</h4>
                          <p class="text-sm text-gray-600 font-normal">Coming Soon</p>
                        </div>
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

            <router-link
              @click="closeMenu"
              to="/coming-soon-blogs"
              class="block mt-4 text-gray-800 hover:text-primary-700 py-3 border-b border-gray-200 text-left font-bold"
            >
              {{ $t('header.blogs') }}
            </router-link>

            <router-link
              @click="closeMenu"
              to="/coming-soon-news"
              class="block mt-4 text-gray-800 hover:text-primary-700 py-3 border-b border-gray-200 text-left font-bold"
            >
              {{ $t('header.news') }}
            </router-link>

            <div class="mt-4 py-3">
              <div @click="toggleMobileLanguage" class="flex justify-between items-center">
                <span
                  :class="[
                    'text-gray-800 font-bold cursor-pointer hover:text-primary-700',
                    { 'text-primary-700': isMobileLanguageOpen }
                  ]"
                  >{{ $t('header.language') }}</span
                >
                <svg
                  :class="{ 'transform rotate-180': isMobileLanguageOpen }"
                  class="w-4 h-4 transition-transform duration-200"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </div>
              <div v-if="isMobileLanguageOpen" class="mt-2">
                <a
                  href="#"
                  @click="changeLanguage('Thai')"
                  :class="[
                    'block p-2 text-sm text-gray-700 hover:bg-gray-100 text-left rounded',
                    { 'text-primary-700': locale === 'TH' }
                  ]"
                >
                  ไทย (TH)
                </a>
                <a
                  href="#"
                  @click="changeLanguage('English')"
                  :class="[
                    'block p-2 text-sm text-gray-700 hover:bg-gray-100 text-left rounded',
                    { 'text-primary-700': locale === 'EN' }
                  ]"
                >
                  English (EN)
                </a>
              </div>
            </div>
          </nav>
        </div>
      </transition>
    </header>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'

export default {
  name: 'HeaderMenu',
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  data() {
    return {
      isMenuOpen: false,
      isLanguageDropdownOpen: false,
      isSolutionsDropdownOpen: false,
      isMobileSolutionsOpen: false,
      isMobileLanguageOpen: false,
      currentLanguage: 'EN'
    }
  },
  methods: {
    closeMenu() {
      this.isMenuOpen = false
      this.isMobileSolutionsOpen = false
      this.isMobileLanguageOpen = false
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    toggleLanguage() {
      this.isLanguageDropdownOpen = !this.isLanguageDropdownOpen
    },
    showLanguageDropdown() {
      this.isLanguageDropdownOpen = true
    },
    hideLanguageDropdown() {
      this.isLanguageDropdownOpen = false
    },

    showSolutionsDropdown() {
      this.isSolutionsDropdownOpen = true
    },
    hideSolutionsDropdown() {
      this.isSolutionsDropdownOpen = false
    },
    changeLanguage(lang) {
      this.locale = lang === 'English' ? 'EN' : 'TH'
      this.isLanguageDropdownOpen = false
      localStorage.setItem('preferredLanguage', this.locale)
      this.closeMenu()
    },
    toggleMobileSolutions() {
      this.isMobileSolutionsOpen = !this.isMobileSolutionsOpen
    },
    toggleMobileLanguage() {
      this.isMobileLanguageOpen = !this.isMobileLanguageOpen
    }
  },
  mounted() {
    const savedLanguage = localStorage.getItem('preferredLanguage')
    if (savedLanguage) {
      this.locale = savedLanguage
    }
  }
}
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
}
</style>

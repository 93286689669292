<template>
  <div id="app" class="bg-gray-100 py-8">
    <section class="text-left">
      <div class="container mx-auto px-4 max-w-3xl">
        <div class="bg-white rounded-2xl border border-[#CECECE] p-8">
          <h1 class="text-xl font-medium mb-2 text-gray-600">{{ $t('contact.connect') }}</h1>
          <h2 class="text-xl font-bold mb-2">{{ $t('contact.ourCompany') }}</h2>
          <p class="text-gray-500">{{ $t('contact.addressFirst') }}</p>
          <p class="mb-4 text-gray-500">
            {{ $t('contact.addressSecond') }} |
            <a
              href="https://maps.app.goo.gl/gtmmJ2oHrz8xHPq37"
              target="_blank"
              class="text-primary-700 hover:text-blue-600 font-bold underline"
              >See map</a
            >
          </p>

          <form @submit.prevent="submitForm" class="space-y-4 mt-4 contact-us-form">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="firstName" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.name') }}<label class="text-red-500">*</label></label
                >
                <input
                  type="text"
                  id="firstName"
                  :placeholder="$t('contact.namePlaceholder')"
                  v-model="form.firstName"
                  required
                  class="mb-4 mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label for="lastName" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.lastName') }}<label class="text-red-500">*</label></label
                >
                <input
                  type="text"
                  id="lastName"
                  :placeholder="$t('contact.lastNamePlaceholder')"
                  v-model="form.lastName"
                  required
                  class="mb-4 mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            </div>

            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="companyEmail" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.email') }}<label class="text-red-500">*</label></label
                >
                <input
                  type="email"
                  id="companyEmail"
                  :placeholder="$t('contact.emailPlaceholder')"
                  v-model="form.companyEmail"
                  required
                  class="mb-4 mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
                <label for="mobileNumber" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.phone') }}<label class="text-red-500">*</label></label
                >
                <input
                  type="tel"
                  id="mobileNumber"
                  :placeholder="$t('contact.phonePlaceholder')"
                  v-model="form.mobileNumber"
                  required
                  class="mb-4 mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="companyName" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.companyName') }}<label class="text-red-500">*</label></label
                >
                <input
                  type="text"
                  id="companyName"
                  :placeholder="$t('contact.companyNamePlaceholder')"
                  v-model="form.companyName"
                  required
                  class="mb-4 mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              <div>
                <label for="role" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.position') }}<label class="text-red-500">*</label></label
                >
                <input
                  type="text"
                  id="role"
                  :placeholder="$t('contact.positionPlaceholder')"
                  v-model="form.role"
                  required
                  class="mb-4 mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label for="businessType" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.businessType') }}<label class="text-red-500">*</label></label
                >
                <select
                  id="businessType"
                  :placeholder="$t('contact.businessTypePlaceholder')"
                  v-model="form.businessType"
                  required
                  class="placeholder-gray-200 mb-4 mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                  <option value="" disabled hidden>
                    {{ $t('contact.businessTypePlaceholder') }}
                  </option>
                  <option value="Agency">Agency</option>
                  <option value="Sale & Marketing">Sale & Marketing</option>
                  <option value="IT Consulting">IT Consulting</option>
                  <option value="Business Consulting">Business Consulting</option>
                  <option value="Wholesale & Retail">Wholesale & Retail</option>
                  <option value="Accommodation & Food Service">Accommodation & Food Service</option>
                  <option value="Logistic and Warehouse">Logistic and Warehouse</option>
                  <option value="Production">Production</option>
                  <option value="Real Estate and Construction">Real Estate and Construction</option>
                  <option value="Financial and Banking">Financial and Banking</option>
                  <option value="Education">Education</option>
                  <option value="Health and Wellness">Health and Wellness</option>
                  <option value="Art and Entertainment">Art and Entertainment</option>
                  <option value="Airline">Airline</option>
                  <option value="Manufacturer">Manufacturer</option>
                  <option value="Insurance">Insurance</option>
                  <option value="Solution Provider">Solution Provider</option>
                  <option value="E-Commerce">E-Commerce</option>
                  <option value="End to End">End to End</option>
                  <option value="Distributor">Distributor</option>
                  <option value="Hospitality">Hospitality</option>
                  <option value="Government">Government</option>
                  <option value="Other">Other</option>
                  <!-- Add more options as needed -->
                </select>
              </div>

              <div>
                <label for="companySize" class="block text-sm font-semibold text-gray-700"
                  >{{ $t('contact.empAmount') }}<label class="text-red-500">*</label></label
                >
                <select
                  id="companySize"
                  :placeholder="$t('contact.empAmountPlaceholder')"
                  v-model="form.companySize"
                  required
                  class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                >
                  <option value="">{{ $t('contact.empAmountPlaceholder') }}</option>
                  <option value="51-100">1-50</option>
                  <option value="51-100">51-100</option>
                  <option value="101-500">101-500</option>
                  <option value="501-1000">501-1000</option>
                  <option value="1001-2000">1001-2000</option>
                  <option value="2001-5000">2001-5000</option>
                  <option value="5000">> 5000</option>
                  <!-- Add more options as needed -->
                </select>
              </div>
            </div>
            <div>
              <label class="block text-sm font-medium text-gray-700 mb-2"
                >{{ $t('contact.interestedProduct') }}<label class="text-red-500">*</label></label
              >
              <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div v-for="(productGroup, groupName) in groupedProducts" :key="groupName">
                  <h3 class="font-light text-sm mb-4">{{ groupName }}</h3>
                  <div class="space-y-2">
                    <label
                      v-for="product in productGroup"
                      :key="product"
                      class="flex items-center space-x-2"
                    >
                      <input
                        type="checkbox"
                        v-model="form.interestedProducts"
                        :value="product"
                        class="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                      />
                      <span class="text-sm">{{ product }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <label for="additionalInfo" class="block text-sm font-medium text-gray-700">{{
                $t('contact.detailLabel')
              }}</label>
              <textarea
                id="additionalInfo"
                v-model="form.additionalInfo"
                :placeholder="$t('contact.detailLabelPlaceholder')"
                rows="4"
                class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              ></textarea>
            </div>
            <label for="additionalInfo" class="block text-sm font-medium text-gray-700"
              >{{ $t('contact.tc') }}<label class="text-red-500">*</label></label
            >
            <div class="flex items-center">
              <input
                type="checkbox"
                id="terms"
                v-model="form.acceptTerms"
                required
                class="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              />
              <label for="terms" class="ml-2 block text-sm text-gray-700">
                {{ $t('contact.youRead') }}
                <a href="/privacy-policy" class="font-semibold text-primary-700 hover:underline">{{
                  $t('contact.privacy')
                }}</a>
                {{ $t('contact.ofTokenomics') }}
              </label>
            </div>

            <!-- Desktop and Tablet View -->
            <div class="hidden md:flex items-center">
              <button
                type="submit"
                :disabled="!isFormValid"
                :class="[
                  'px-6 py-2 rounded-[14px] font-semibold text-sm transition duration-300 w-full sm:w-auto',
                  isFormValid
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-[#DADADA] text-gray-700 cursor-not-allowed'
                ]"
              >
                {{ $t('contact.submit') }}
              </button>

              <div class="flex flex-wrap items-center">
                <p class="text-sm text-[#7E7E7E] font-bold mr-auto ml-4">
                  {{ $t('contact.orContact') }}
                </p>

                <div class="ml-3 flex items-center">
                  <img class="-mx-8" src="../assets/form-phone-icon.svg" />

                  <a
                    href="tel:065-639-1946"
                    class="text-sm text-blue-600 font-semibold hover:underline"
                    >065-639-1946</a
                  >
                </div>
              </div>
            </div>

            <!-- Mobile View -->
            <div class="flex flex-col md:hidden items-center">
              <button
                type="submit"
                :disabled="!isFormValid"
                :class="[
                  'px-6 py-2 rounded-[14px] font-semibold text-sm transition duration-300 w-full sm:w-auto',
                  isFormValid
                    ? 'bg-blue-600 text-white hover:bg-blue-700'
                    : 'bg-[#DADADA] text-gray-700 cursor-not-allowed'
                ]"
              >
                {{ $t('contact.submit') }}
              </button>

              <div class="flex flex-row items-center whitespace-nowrap">
                <p class="text-sm text-[#7E7E7E] font-bold">
                  {{ $t('contact.orContact') }}
                </p>

                <div class="ml-3 flex items-center">
                  <img class="-mx-8" src="../assets/form-phone-icon.svg" />

                  <a
                    href="tel:065-639-1946"
                    class="text-sm text-blue-600 font-semibold hover:underline"
                    >065-639-1946</a
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- Modal -->
        <div
          v-if="showModal"
          class="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center"
        >
          <div
            class="bg-white p-8 rounded-lg shadow-xl max-w-md mx-auto text-center sm:min-w-96 relative"
          >
            <button
              @click="closeModal()"
              class="absolute right-4 top-4 text-gray-800 hover:text-gray-600 focus:outline-none"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            <div class="mb-4">
              <img src="../assets/complete.svg" alt="Tokenomics Logo" class="mx-auto w-12 h-12" />
            </div>
            <h2 class="text-xl font-bold mb-4">{{ $t('contact.completeTitle') }}</h2>
            <p class="mb-6">{{ $t('contact.completeDetail') }}</p>
            <button
              @click="goToHome"
              class="border border-primary-700 bg-white text-primary-700 text-sm font-semibold px-6 py-2 rounded-lg hover:bg-primary-700 hover:text-white transition duration-300"
            >
              {{ $t('contact.backToHome') }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { useI18n } from 'vue-i18n'

export default {
  name: 'ComingSoon',
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  data() {
    return {
      showModal: false,
      form: {
        firstName: '',
        lastName: '',
        companyEmail: '',
        mobileNumber: '',
        companyName: '',
        role: '',
        businessType: '',
        companySize: '',
        interestedProducts: [],
        additionalInfo: '',
        acceptTerms: false
      },
      groupedProducts: {
        Advisory: [
          'PDPA Consult',
          'HAIT Consult',
          'Data Gov Consult',
          'DPS Consult',
          'IT Security Consult'
        ],
        Service: [
          'PDPA As-a-Service',
          'HAIT As-a-Service',
          'Data Gov As-a-Service',
          'Data As-a-Service',
          'KYC As-a-Service'
        ],
        Fintech: ['Invest in Digital Token (ICO)', 'Raise Fund via ICO']
      }
    }
  },
  methods: {
    async submitForm() {
      try {
        const messageTemplate = this.createMessageTemplate()
        await axios.post('https://api.zimpleadvisory.co.th/send-email', {
          customerName: `${this.form.firstName} ${this.form.lastName}`,
          message: messageTemplate
        })

        this.showModal = true
      } catch (error) {
        console.error('Error submitting form:', error)
        // Handle error (e.g., show error message to user)
        // Temporary
        window.alert('Unexpected error, please try again later.')
      }
    },
    createMessageTemplate() {
      const interestedProductsString = this.form.interestedProducts.join(', ')
      return `
        แบบฟอร์มติดต่อบริษัท Zimple Advisory:

        Name: ${this.form.firstName} ${this.form.lastName}
        Company Email: ${this.form.companyEmail}
        Mobile Number: ${this.form.mobileNumber}
        Company Name: ${this.form.companyName}
        Role: ${this.form.role}
        Business Type: ${this.form.businessType}
        Company Size: ${this.form.companySize}
        Interested Products: ${interestedProductsString}
        Additional Information: ${this.form.additionalInfo}
      `
    },
    goToHome() {
      this.$router.push('/') // Assuming you're using Vue Router
    },
    closeModal() {
      window.location.reload()
    }
  },
  mounted() {
    const savedLanguage = localStorage.getItem('preferredLanguage')
    if (savedLanguage) {
      this.locale = savedLanguage
    }
  },
  computed: {
    isFormValid() {
      return (
        this.form.firstName.trim() !== '' &&
        this.form.lastName.trim() !== '' &&
        this.form.companyEmail.trim() !== '' &&
        this.form.mobileNumber.trim() !== '' &&
        this.form.companyName.trim() !== '' &&
        this.form.role.trim() !== '' &&
        this.form.businessType !== '' &&
        this.form.companySize !== '' &&
        this.form.interestedProducts.length > 0 &&
        this.form.acceptTerms
      )
    }
  }
}
</script>
<style scoped>
@media (max-width: 768px) {
  .grid-cols-1 > div:nth-child(1) {
    order: 1;
  }
  .grid-cols-1 > div:nth-child(2) {
    order: 2;
  }
  .grid-cols-1 > div:nth-child(3) {
    order: 3;
  }
}
button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.contact-us-form {
  input,
  textarea {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
  }
  select {
    font-size: 14px;
  }
}
</style>

import { createI18n } from 'vue-i18n'
import { TH } from './th'
import { EN } from './en'

const messages = {
  TH,
  EN
}

const i18n = createI18n({
  legacy: false, // Use Composition API
  locale: 'TH', // Set default locale
  fallbackLocale: 'TH',
  messages
})

export default i18n

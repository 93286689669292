<template>
  <div id="app">
    <section class="py-6">
      <div class="container mx-auto px-4 py-8">
        <div class="text-center mb-12">
          <div
            class="inline-block bg-primary-100 text-primary-700 px-4 py-2 rounded-lg text-sm font-semibold mb-8"
          >
            {{ $t("aboutUs.aboutUs") }}
          </div>
          <h1 class="text-3xl sm:text-4xl font-bold mb-4">
            <span class="text-primary-700">FINTECH</span><span class="font-light text-primary-700"> | </span>
            <span class="text-primary-700">REGTECH</span><span class="font-light text-primary-700"> | </span>
            <span class="text-primary-700">CONSULTANCY</span>
          </h1>
          <p class="max-w-2xl mx-auto mt-8 text-gray-600">
            {{ $t("aboutUs.firstDetail") }}

            <br /><br />

            <!-- {{ $t("aboutUs.secondDetail") }}

            <label class="max-w-2xl mx-auto mt-4 text-gray-600 font-bold">
              {{ $t("aboutUs.fiftyMillion") }}
            </label>

            {{ $t("aboutUs.thirdDetail") }} -->
          </p>
        </div>

        <div
          class="flex flex-col md:flex-row space-y-8 md:space-y-0 md:space-x-8 mb-12 xl:w-5/6 mx-auto"
        >
          <div
            class="flex flex-col w-full md:w-1/2 bg-primary-700 rounded-[50px] pt-8 px-8 lg:px-16 pb-0 text-white"
          >
            <div
              class="w-8/24 px-6 mx-auto bg-white text-primary-700 inline-block px-4 py-2 rounded-lg text-sm font-semibold mb-4"
            >
            {{ $t("aboutUs.mission") }}
            </div>
            <p class="mb-4 md:w-11/12 p-2 mx-auto flex-grow">
              {{ $t("aboutUs.missionDetail") }}
            </p>
            <img src="../assets/mission.svg" alt="Mission" class="w-10/12 md:w-4/5 lg:w-full mt-auto mx-auto" />
          </div>
          <div class="flex flex-col w-full md:w-1/2 bg-gray-100 rounded-[50px] pt-8 px-8 lg:px-16 pb-0">
            <div
              class="w-8/24 px-6 mx-auto bg-primary-700 text-white inline-block px-4 py-2 rounded-lg text-sm font-semibold mb-4"
            >
            {{ $t("aboutUs.vision") }}
            </div>
            <p class="mb-4 md:w-11/12 p-2 mx-auto">
              {{ $t("aboutUs.visionDetail") }}
            </p>
            <img src="../assets/vision.svg" alt="Vision" class="w-10/12 md:w-4/5 lg:w-full mt-auto mx-auto" />
          </div>
        </div>
      </div>
    </section>
    <section class="bg-gray-100 md:pl-4 py-12 md:mt-0 -mt-14 mx-2">
      <div class="text-center">
        <h2 class="text-3xl font-bold mb-4">{{ $t("aboutUs.youReady") }}</h2>
        <p class="mb-6">
          {{ $t("aboutUs.easyStartFirst") }}
          <span class="text-primary-700 font-bold">Zimple Advisory</span>

          {{ $t("aboutUs.easyStartSecond") }}

          </p>
        <router-link to="/contact">
          <button
            class="font-bold border-[1px] border-primary-700 text-primary-700 px-6 py-2 rounded-xl hover:text-white hover:bg-primary-700 transition-colors"
          >
            {{ $t("header.contactUs") }}
          </button>
        </router-link>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "AboutUsPage",
};
</script>

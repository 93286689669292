<template>
  <div id="app">
    <section class="wrapper_section1">
      <div
        class="container mx-auto lg:mr-2 px-3 md:px-4 lg:px-6 flex flex-col md:flex-row items-center"
      >
        <div class="w-full md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <h1 class="text-4xl md:text-5xl font-bold">
            <span class="text-primary-700">{{ displayedWord }}</span>
            <span class="text-primary-700 typing-cursor">|</span>
          </h1>
          <p
            class="text-[#0c1d4e] text-md sm:text-lg xl:text-xl uppercase whitespace-pre mt-4 pr-0 md:pr-8"
          >
            <span class="font-bold">{{ $t('home.firstDetailFirst') }}</span
            >{{ $t('home.firstDetailSecond') }}
          </p>
          <p class="text-4xl md:text-5xl font-bold mt-4 text-primary-700">YOUR BUSINESS</p>
        </div>
        <div class="w-full">
          <img
            src="../assets/home-1.svg"
            alt="Home Image1"
            class="w-3/4 md:w-7/12 h-auto mx-auto"
          />
        </div>
      </div>
    </section>
    <!-- </section> -->
    <section class="bg-gray-100 md:pl-4 py-12 wrapper_section2">
      <div class="container mx-auto px-2">
        <div class="flex flex-col md:flex-row items-center">
          <div class="w-3/5 md:w-1/2 order-2 md:order-1 mt-8 md:mt-0">
            <img
              src="../assets/home-2.svg"
              alt="Home Image2"
              class="w-3/4 md:w-7/12 h-auto mx-auto"
            />
          </div>
          <div
            class="w-full md:w-4/5 text-center md:text-left md:ml-8 order-1 md:order-2 md:pr-4 lg:pr-8 xl:pr-36 md:ml-36"
          >
            <div class="md:hidden">
              <h1 class="text-3xl font-bold text-primary-700">FINTECH</h1>

              <h1 class="text-3xl font-bold text-primary-700">REGTECH</h1>

              <h1 class="text-3xl font-bold text-primary-700">CONSULTANCY</h1>
              <p class="text-gray-700 mt-4 px-3 sm:px-0">
                {{ $t('home.secondDetail') }}
              </p>
            </div>
            <div class="hidden md:block">
              <h1 class="text-xl md:text-3xl font-bold text-primary-700">
                FINTECH | REGTECH | CONSULTANCY
              </h1>
              <p class="text-gray-700 mt-4 px-3 sm:px-0">
                {{ $t('home.secondDetail') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- </section> -->

    <!-- Why Choose Us Section -->
    <section class="bg-white py-12">
      <div class="container mx-auto px-6">
        <div class="container mx-auto text-center px-1 py-12">
          <div class="inline-block">
            <div class="w-16 h-1 bg-primary-700 mx-auto mb-4"></div>
            <h2 class="text-[24px] sm:text-[36px] mb-6">Why Enterprise</h2>
            <p class="text-[24px] sm:text-[36px] font-bold">choose Zimple Advisory ?</p>
          </div>
        </div>

        <div class="mt-2 flex flex-wrap justify-center">
          <div class="sm:w-3/4 md:w-1/2 lg:w-1/3 sm:p-4 py-4 text-center">
            <div class="bg-gray-100 h-full px-10 py-8 rounded-lg">
              <img
                src="../assets/service-1.svg"
                alt="Experiences Image"
                class="w-1/5 h-auto mx-auto mb-4"
              />
              <h3 class="text-xl font-semibold text-gray-900">Work with Professionals</h3>
              <p class="mt-4 text-gray-700">
                {{ $t('home.firstServiceDetail') }}
              </p>
            </div>
          </div>

          <div class="sm:w-3/4 md:w-1/2 lg:w-1/3 sm:p-4 py-4 text-center">
            <div class="bg-gray-100 h-full px-10 py-8 rounded-lg">
              <img
                src="../assets/service-2.svg"
                alt="Service Mind Image"
                class="w-1/5 h-auto mx-auto mb-4"
              />
              <h3 class="text-xl font-semibold text-gray-900">Service Mind</h3>
              <p class="mt-4 text-gray-700">
                {{ $t('home.secondServiceDetail') }}
              </p>
            </div>
          </div>

          <div class="sm:w-3/4 md:w-1/2 lg:w-1/3 sm:p-4 py-4 text-center">
            <div class="bg-gray-100 h-full px-10 py-8 rounded-lg">
              <img
                src="../assets/service-3.svg"
                alt="Compliance Image"
                class="w-1/5 h-auto mx-auto mb-4"
              />
              <h3 class="text-xl font-semibold text-gray-900">Compliance Simplified</h3>
              <p class="mt-4 text-gray-700">
                {{ $t('home.thirdServiceDetail') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- </section> -->

    <!-- Customers Section -->
    <section class="bg-gray-100">
      <div class="relative">
        <img class="absolute left-0 top-0 z-0" src="../assets/customer-background-1.svg" />
        <img class="absolute bottom-0 right-0 z-0" src="../assets/customer-background-2.svg" />

        <div class="container relative mx-auto px-6 text-center py-16 z-10">
          <div class="w-16 h-1 bg-primary-700 mx-auto mb-4"></div>
          <h2 class="text-2xl text-gray-900 mb-8">Our <span class="font-bold">Customers</span></h2>

          <!-- Desktop and Tablet View -->
          <div class="hidden md:flex justify-center items-center space-x-4 mx-auto lg:mx-40">
            <div v-for="customer in customers" :key="customer.id" class="flex-1">
              <a :href="customer.website" target="_blank">
                <img
                  :src="customer.logo"
                  :alt="customer.name"
                  class="mx-auto h-32 object-contain transition-opacity duration-300"
                  @mouseover="hoverCustomer(customer.id)"
                  @mouseout="unhoverCustomer(customer.id)"
                  :ref="'customer' + customer.id"
                />
              </a>
            </div>
          </div>

          <!-- Mobile View -->
          <div class="md:hidden relative overflow-hidden" style="height: 8rem">
            <transition-group
              name="slide-fade"
              tag="div"
              class="flex justify-center items-center"
              style="position: absolute; width: 100%"
            >
              <div v-for="customer in visibleCustomers" :key="customer.id" class="w-full">
                <img
                  :src="customer.logo"
                  :alt="customer.name"
                  class="mx-auto h-32 object-contain"
                  @mouseover="hoverMobileCustomer(customer.id)"
                  @mouseout="unhoverMobileCustomer(customer.id)"
                  :ref="'customer_mobile' + customer.id"
                />
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </section>

    <!-- Team Section -->
    <section class="bg-white py-12">
      <div class="container mx-auto px-12 xl:px-24 py-12">
        <div class="text-center mb-12">
          <div class="w-16 h-1 bg-primary-700 mx-auto mb-4"></div>
          <h2 class="text-2xl md:text-2xl">Our <span class="font-bold">Team</span></h2>
        </div>

        <div class="relative">
          <div
            class="h-[220px] xs:h-[300px] sm:h-[300px] md:h-[320px] lg:h-[350px] overflow-hidden"
          >
            <transition-group name="fade" tag="div" class="overflow-hidden whitespace-nowrap">
              <div
                v-for="member in visibleMembers"
                :key="member.name"
                class="inline-block w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-4 transition-all duration-500 ease-in-out"
              >
                <div class="flex flex-col items-center">
                  <img
                    :src="member.image"
                    :alt="$t(member.name)"
                    class="w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 rounded-full mb-4 object-cover"
                  />
                  <h3 class="text-lg sm:text-xl font-semibold text-center mb-1">
                    {{ $t(member.name) }}
                  </h3>
                  <p class="text-sm text-gray-600 text-center">
                    {{ member.position }}
                  </p>
                </div>
              </div>
            </transition-group>
          </div>

          <!-- Fixed position slide buttons -->
          <div class="absolute bottom-0 left-0 right-0 flex justify-center mt-4">
            <button
              v-for="index in slideCount"
              :key="index - 1"
              @click="goToSlide(index - 1)"
              class="w-2 h-2 sm:w-3 sm:h-3 rounded-full mx-1 focus:outline-none transition-all duration-300"
              :class="currentIndex === index - 1 ? 'bg-blue-600 scale-125' : 'bg-gray-300'"
            ></button>
          </div>
        </div>
      </div>
    </section>

    <!-- Certifications Section -->
    <section class="bg-gray-100 py-12">
      <div class="container mx-auto px-6 text-center">
        <div
          class="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-7 lg:grid-cols-7 gap-4 items-center justify-items-center mx-auto mx-16"
        >
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-1.svg"
              alt="Certification 1"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-2.svg"
              alt="Certification 2"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-3.svg"
              alt="Certification 3"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-4.svg"
              alt="Certification 4"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-5.svg"
              alt="Certification 5"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-6.svg"
              alt="Certification 6"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[120px] h-auto mb-6">
            <img src="../assets/cert-7.svg" alt="Certification 7" class="object-contain img_cert" />
          </div>
          <div class="w-[7rem] sm:w-[9rem] lg:w-[12rem] h-auto mb-6">
            <img
              src="../assets/cert-8.svg"
              alt="Certification 8"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-9.svg"
              alt="Certification 9"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[100px] h-auto mb-6">
            <img
              src="../assets/cert-10.svg"
              alt="Certification 10"
              class="w-full h-auto object-contain"
            />
          </div>
          <div class="w-full max-w-[120px] h-auto mb-6">
            <img
              src="../assets/cert-11.svg"
              alt="Certification 11"
              class="object-contain img_cert"
            />
          </div>
          <div class="w-full max-w-[120px] h-auto mb-6">
            <img
              src="../assets/cert-12.svg"
              alt="Certification 12"
              class="object-contain img_cert"
            />
          </div>
          <div class="w-full max-w-[120px] h-auto mb-6">
            <img
              src="../assets/cert-13.svg"
              alt="Certification 13"
              class="object-contain img_cert"
            />
          </div>
          <div class="w-full max-w-[120px] h-auto mb-6">
            <img
              src="../assets/cert-15.png"
              alt="Certification 14"
              class="object-contain img_cert"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  name: 'HomePage',
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  data() {
    return {
      isMenuOpen: false,
      currentIndex: 0,
      autoSlideInterval: null,
      currentCustomerIndex: 0,
      customerSlideInterval: null,
      windowWidth: window.innerWidth,
      customers: [
        {
          id: 1,
          name: 'Zimpligital',
          logo: '../assets/customer-2.svg',
          hoverLogo: '../assets/customer-2-hover.svg',
          website: 'https://zimpligital.com/'
        },
        {
          id: 2,
          name: 'Zimple Exchange',
          logo: '../assets/customer-3.svg',
          hoverLogo: '../assets/customer-3-hover.svg',
          website: 'https://exchange.zimple.co/'
        },
        {
          id: 3,
          name: 'Cplinter',
          logo: '../assets/customer-4.svg',
          hoverLogo: '../assets/customer-4-hover.svg',
          website: 'https://www.cplinter.com/'
        }
      ],
      teamMembers: [
        {
          name: 'team.akkayutB',
          position: 'CEO (Co-Founder)',
          image: '../assets/team-1.png'
        },
        {
          name: 'team.thamakornB',
          position: 'Senior Consultant (Co-Founder)',
          image: '../assets/team-2.png'
        },
        {
          name: 'team.chalisaT',
          position: 'Senior Consultant (Co-Founder)',
          image: '../assets/team-3.png'
        },
        {
          name: 'team.sumanaM',
          position: 'Product Manager',
          image: '../assets/team-4.png'
        },
        {
          name: 'team.papawarinK',
          position: 'Senior Business Developer',
          image: '../assets/team-5.png'
        },
        {
          name: 'team.nutthapongP',
          position: 'Senior Business Developer',
          image: '../assets/team-6.png'
        },
        {
          name: 'team.kesineeL',
          position: 'Senior Business Analyst',
          image: '../assets/team-7.png'
        },
        {
          name: 'team.saipraewR',
          position: 'Senior Sales Consultant',
          image: '../assets/team-8.png'
        }
      ],
      words: ['FINTECH', 'REGTECH', 'CONSULTANCY'],
      currentWordIndex: 0,
      displayedWord: '',
      isDeleting: false,
      typingSpeed: 150,
      deletingSpeed: 75,
      pauseBeforeDelete: 1000,
      pauseBeforeNextWord: 500
    }
  },
  computed: {
    itemsPerView() {
      if (this.windowWidth < 640) return 1 // Extra small screens
      if (this.windowWidth < 768) return 2 // Small screens
      if (this.windowWidth < 1024) return 3 // Medium screens
      return 4 // Large screens and up
    },
    slideCount() {
      return Math.ceil(this.teamMembers.length / this.itemsPerView)
    },
    visibleMembers() {
      const start = this.currentIndex * this.itemsPerView
      return this.teamMembers.slice(start, start + this.itemsPerView)
    },
    visibleCustomers() {
      return [this.customers[this.currentCustomerIndex]]
    }
  },
  methods: {
    nextCustomer() {
      this.currentCustomerIndex = (this.currentCustomerIndex + 1) % this.customers.length
    },
    startCustomerSlide() {
      if (window.innerWidth < 768) {
        this.customerSlideInterval = setInterval(() => {
          this.nextCustomer()
        }, 3000) // Change customer logo every 3 seconds
      }
    },
    stopCustomerSlide() {
      clearInterval(this.customerSlideInterval)
    },
    goToSlide(index) {
      this.currentIndex = index
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slideCount
    },
    startAutoSlide() {
      this.autoSlideInterval = setInterval(() => {
        this.nextSlide()
      }, 10000) // Change slide every 5 seconds
    },
    stopAutoSlide() {
      clearInterval(this.autoSlideInterval)
    },
    handleResize() {
      this.windowWidth = window.innerWidth
      const oldItemsPerView = this.itemsPerView
      this.$nextTick(() => {
        if (this.itemsPerView !== oldItemsPerView) {
          this.currentIndex = Math.min(this.currentIndex, this.slideCount - 1)
        }
      })
      this.stopCustomerSlide()
      this.startCustomerSlide()
    },

    typeWord() {
      const currentWord = this.words[this.currentWordIndex]

      if (this.isDeleting) {
        this.displayedWord = currentWord.substring(0, this.displayedWord.length - 1)
      } else {
        this.displayedWord = currentWord.substring(0, this.displayedWord.length + 1)
      }

      let typingSpeed = this.isDeleting ? this.deletingSpeed : this.typingSpeed

      if (!this.isDeleting && this.displayedWord === currentWord) {
        typingSpeed = this.pauseBeforeDelete
        this.isDeleting = true
      } else if (this.isDeleting && this.displayedWord === '') {
        this.isDeleting = false
        this.currentWordIndex = (this.currentWordIndex + 1) % this.words.length
        typingSpeed = this.pauseBeforeNextWord
      }

      setTimeout(this.typeWord, typingSpeed)
    },
    hoverCustomer(customerId) {
      const img = this.$refs['customer' + customerId][0]
      const customer = this.customers.find((c) => c.id === customerId)
      img.src = customer.hoverLogo
    },
    unhoverCustomer(customerId) {
      const img = this.$refs['customer' + customerId][0]
      const customer = this.customers.find((c) => c.id === customerId)
      img.src = customer.logo
    },
    hoverMobileCustomer(customerId) {
      const img = this.$refs['customer_mobile' + customerId][0]
      if (!img) return
      const customer = this.customers.find((c) => c.id === customerId)
      img.src = customer.hoverLogo
    },
    unhoverMobileCustomer(customerId) {
      const img = this.$refs['customer_mobile' + customerId][0]
      if (!img) return
      const customer = this.customers.find((c) => c.id === customerId)
      img.src = customer.logo
    }
  },
  mounted() {
    this.startAutoSlide()
    window.addEventListener('resize', this.handleResize)
    this.startCustomerSlide()
    const savedLanguage = localStorage.getItem('preferredLanguage')
    if (savedLanguage) {
      this.locale = savedLanguage
    }
    this.typeWord()
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  beforeUnmount() {
    this.stopAutoSlide()
    window.removeEventListener('resize', this.handleResize)
    this.stopCustomerSlide()
    clearInterval(this.wordInterval)
  }
}
</script>

<style scoped>
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.typing-cursor {
  animation: blink 0.7s infinite;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(50px);
}
.fade-enter-to,
.fade-leave-from {
  opacity: 0;
  transform: translateX(50px);
}
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
.slide-fade-leave-active {
  position: absolute;
}

.img_cert {
  width: 120px;
  height: auto;
}

.wrapper_section1 {
  padding: 0px;
  margin-top: 80px;
  margin-bottom: 100px;
}

.wrapper_section2 {
  padding-top: 60px;
  padding-bottom: 60px;
}

@media only screen and (max-width: 767px) {
  .wrapper_section1 {
    padding: 0px;
    margin-top: 50px;
    margin-bottom: 75px;
  }

  .wrapper_section2 {
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
</style>

import { createRouter, createWebHistory } from "vue-router";
import Home from "../pages/Home.vue";
import ComingSoon from "../pages/ComingSoon.vue";
import Contact from "../pages/Contact.vue";
import AboutUs from "../pages/AboutUs.vue";

const routes = [
  { path: "/", name: "home", component: Home },
  {
    path: "/coming-soon-ico-portal",
    name: "ico-portal",
    component: ComingSoon,
  },
  { path: "/coming-soon-advisory", name: "advisory", component: ComingSoon },
  { path: "/coming-soon-services", name: "services", component: ComingSoon },
  { path: "/coming-soon-blogs", name: "blogs", component: ComingSoon },
  { path: "/coming-soon-news", name: "news", component: ComingSoon },
  { path: "/contact", name: "contact", component: Contact },
  { path: "/about-us", name: "about-us", component: AboutUs },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () => import("../pages/PrivacyPolicy.vue"),
  },
  {
    path: "/cookies-policy",
    name: "cookies-policy",
    component: () => import("../pages/CookiesPolicy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: "text-primary-700",
});

router.beforeEach((to, from, next) => {
  // This will run before each route change
  window.scrollTo(0, 0);
  next();
});

export default router;

<template>
  <div id="app">
    <!-- Footer -->

    <footer class="bg-customColor text-white pt-14 pb-8">
      <div class="container mx-auto px-4">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <!-- Company Info -->
          <div class="space-y-4 text-left">
            <img src="../assets/tokenomics-logo.svg" alt="Tokenomics Logo" class="w-72 h-20" />
            <h2 class="text-xl font-bold">{{ $t('footer.companyName') }}</h2>
            <p class="font-light">{{ $t('footer.taxPayerId') }} : 0105565032074</p>
            <p class="font-light">
              {{ $t('footer.addressFirst') }}<br />{{ $t('footer.addressSecond') }}
            </p>
            <p class="font-light">{{ $t('footer.officeHours') }}</p>
          </div>

          <!-- Legal -->
          <div class="space-y-4 text-left">
            <h2 class="text-xl font-bold">{{ $t('footer.legal') }}</h2>
            <ul class="space-y-2">
              <router-link to="/privacy-policy" exactActiveClass="no-active">
                <li class="mb-2">
                  <a
                    href="/privacy-policy"
                    class="font-light hover:text-gray-300 hover:underline"
                    >{{ $t('footer.privacyPolicy') }}</a
                  >
                </li>
              </router-link>
              <router-link to="/cookies-policy" exactActiveClass="no-active">
                <li>
                  <a
                    href="/cookies-policy"
                    class="font-light hover:text-gray-300 hover:underline"
                    >{{ $t('footer.cookiesPolicy') }}</a
                  >
                </li>
              </router-link>
            </ul>
          </div>

          <!-- Contact Us -->
          <div class="space-y-4 text-left">
            <h2 class="text-xl font-bold">{{ $t('footer.contactUs') }}</h2>
            <p class="font-light flex items-center">
              <svg
                class="w-5 h-5 mr-2 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                ></path>
              </svg>
              +66 65-639-1946
            </p>
            <p class="font-light flex items-center">
              <svg
                class="w-5 h-5 mr-2 flex-shrink-0"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                ></path>
              </svg>
              business@tokenomics.co.th
            </p>
            <br /><br />
            <div class="flex space-x-4 mt-4">
              <a
                href="https://www.facebook.com/profile.php?id=61559896512415"
                target="_blank"
                class="text-white hover:text-gray-300"
              >
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                  />
                </svg>
              </a>
              <a
                href="https://www.linkedin.com/company/tokenomics-co"
                target="_blank"
                class="text-white hover:text-gray-300"
              >
                <svg
                  class="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.225 0z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div class="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400 text-sm">
          <p>Copyright © 2024 Zimple Advisory. All rights reserved</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
export default {
  name: 'FooterPart',
  setup() {
    const { t, locale } = useI18n()
    return { t, locale }
  },
  mounted() {
    const savedLanguage = localStorage.getItem('preferredLanguage')
    if (savedLanguage) {
      this.locale = savedLanguage
    }
  }
}
</script>
